import { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Card, Typography, Grid, useTheme } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import {
    Divider,
    FieldTypeValuesAssignmentInfo,
    Spinner,
    Tooltip,
} from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useGroupByRisks, useRemoveItemFromCart } from 'hooks'
import { Icon } from 'packages/eid-icons'
import { useCartItemStyles, useShowMoreButtonStyles } from './styles'
import CartItemViolation from './CartItemViolation'
import classNames from 'classnames'
import { CartItemComment } from './CartItemComment'
import Button from 'components/Button'
import { hasValidJson, updateJsonMappingWithApiFormat } from 'packages/core'
const NS = 'public'
const assignmentTypeLabelKey = {
    Add: 'Common_RequestAccess',
    Remove: 'Common_RevokeAccess',
    UpdateTimeConstrain: 'Common_UpdateTimeConstraint',
}

const MyTypography = withStyles((theme) => ({
    root: {},
    h2: {
        color: theme?.palette?.common?.white,
        fontSize: '1.8rem',
        fontWeight: '500',
        lineHeight: '1.2',
    },
    h3: {
        fontSize: '2.2rem',
        color: theme?.palette?.common?.black,
        textTransform: 'uppercase',
    },
    h4: {
        fontSize: '1.4rem',
        fontWeight: 'normal',
        color: theme?.palette?.common?.black,
    },
    caption: {
        fontSize: '1.4rem',
        fontWeight: 'normal',
        color: '#8a8989',
    },
    subtitle1: {
        position: 'absolute',
        fontSize: '15rem',
        right: '1.7rem',
        top: '-10rem',
        fontWeight: '300',
        color: theme?.palette?.common?.white,
        opacity: '0.1',
    },
}))(Typography)

const CartItem = (props) => {
    const theme = useTheme()

    const classes = useCartItemStyles({
        bgColor: theme?.palette?.primary?.main,
        assignmentType: props.item.assignmentType,
    })

    const { t } = useTranslation()
    const { item, index, onCommentChange, risks } = props

    const riskGroupedByLocalRiskId = useGroupByRisks(risks)

    const [removeItemFromCart, { isLoading: isRemoving }] =
        useRemoveItemFromCart()

    const showApplicationName = () => {
        return (
            item?.resourceType_Name === 'RoleDefinition' ||
            item?.resourceType_Name === 'AzLocalRight' ||
            (item?.resourceType_Name === 'ManagementRole' &&
                item?.resourceTypeRoleId !==
                    '00000000-0000-0000-0000-000000000000') ||
            false
        )
    }

    const getParsedObject = (apiDataObject) => {
        try {
            const parsedObject = Array.isArray(apiDataObject)
                ? apiDataObject[0]
                : apiDataObject
            return parsedObject
        } catch (err) {}
        return apiDataObject
    }

    return (
        <Card className={classes.cartItem} key={index}>
            <Box className={classes.cartItemHeader}>
                <Box className={classes.itemLabel}>
                    <Box className={classes.addOrRemoveLabel}>
                        {t(assignmentTypeLabelKey[props.item.assignmentType])}
                    </Box>
                    {showApplicationName() && (
                        <Box className={classes.resourceTypeLabel} zIndex="10">
                            <Icon name={'ApplicationsSmall'} />
                            <Box component="span" marginLeft="0.8rem">
                                {t('Application')}
                            </Box>
                        </Box>
                    )}
                    <Box
                        className={classNames(classes.resourceTypeLabel, {
                            [classes.darkBg]: showApplicationName(),
                        })}
                    >
                        <Icon
                            name={
                                item.resourceType_Name === 'AzLocalRight'
                                    ? 'AppRole'
                                    : item.resourceType_Name
                            }
                        />
                        <Box component="span" marginLeft="0.8rem">
                            {t(`Common_${item.resourceType_Name}`)}
                        </Box>
                    </Box>
                </Box>
                <Box flex="0.9">
                    <MyTypography
                        variant="h2"
                        style={{ wordBreak: 'break-word' }}
                    >
                        {item.resource_FriendlyName}
                    </MyTypography>
                </Box>
                <Tooltip title={t('Common_RemoveFromCart')}>
                    <Box
                        className={classes.deleteIconContainer}
                        onClick={() => {
                            if (isRemoving) return
                            removeItemFromCart(item.id)
                        }}
                    >
                        {isRemoving ? (
                            <Spinner
                                color={theme?.palette?.error?.main}
                                size={0.6}
                            />
                        ) : (
                            <Icon className="delete" name="Delete" />
                        )}
                    </Box>
                </Tooltip>
            </Box>
            <Box
                className={classNames(classes.cartItemContent, {
                    [classes.cartItemContentWithFieldTypes]:
                        item?.fieldTypeValueJson &&
                        hasValidJson(
                            updateJsonMappingWithApiFormat(
                                JSON.parse(item.fieldTypeValueJson),
                                false,
                            ),
                        ),
                })}
            >
                <Box
                    className={classNames(classes.cartCommentContainer, {
                        [classes.cartCommentContainerFieldType]:
                            item?.fieldTypeValueJson &&
                            hasValidJson(
                                updateJsonMappingWithApiFormat(
                                    JSON.parse(item.fieldTypeValueJson),
                                    false,
                                ),
                            ),
                    })}
                >
                    <Box className={classes.cartComment}>
                        <CartItemComment
                            item={item}
                            containsRisks={
                                riskGroupedByLocalRiskId &&
                                riskGroupedByLocalRiskId.length > 0
                            }
                            onCommentChange={onCommentChange}
                        />
                    </Box>
                    <Box className={classes.timeConstraints}>
                        {item.timeConstraintActive ? (
                            <>
                                {item.startDateUtc && (
                                    <Attribute
                                        label={t('Common_StartDateTime')}
                                        value={moment
                                            .utc(item.startDateUtc)
                                            .local()
                                            .format('L LT')}
                                    />
                                )}
                                {item.endDateUtc && (
                                    <Attribute
                                        marginTop="0.8rem"
                                        label={t('Common_EndDateTime')}
                                        value={moment
                                            .utc(item.endDateUtc)
                                            .local()
                                            .format('L LT')}
                                    />
                                )}
                            </>
                        ) : (
                            <Attribute
                                label={t('Common_ExpiresOn')}
                                value="-"
                            />
                        )}
                    </Box>
                </Box>
                {showApplicationName() && (
                    <Box
                        className={classNames(classes.cartCommentContainer, {
                            [classes.cartCommentContainerFieldType]:
                                item?.fieldTypeValueJson &&
                                hasValidJson(
                                    updateJsonMappingWithApiFormat(
                                        JSON.parse(item.fieldTypeValueJson),
                                        false,
                                    ),
                                ),
                        })}
                    >
                        <Attribute
                            className={classes.horizontalOrVertical}
                            label={t('Application')}
                            value={item.resourceTypeRole_FriendlyName}
                        />
                    </Box>
                )}
                <Box className={classes.moreAttributes}>
                    {item.resourceType_Name === 'BusinessRole' && (
                        <Attribute
                            className={classes.horizontalOrVertical}
                            label={t('BusinessRoles_DifferentiationValue')}
                            value={item.location_FriendlyName}
                        />
                    )}

                    {item.resourceType_Name === 'SharedFolder' && (
                        <Attribute
                            className={classes.horizontalOrVertical}
                            label={t('Common_AccessLevel')}
                            value={item.resourceTypeRole_FriendlyName}
                        />
                    )}
                    {item.resourceType_Name === 'AzureRole' &&
                        item.location_FriendlyName && (
                            <Attribute
                                className={classes.horizontalOrVertical}
                                label={t('AzureRbacRoles_Scope')}
                                value={item.location_FriendlyName}
                            />
                        )}

                    {item.resourceType_Name === 'MailBox' && (
                        <Attribute
                            className={classes.horizontalOrVertical}
                            label={t('Common_AccessLevel')}
                            value={item.resourceTypeRole_FriendlyName}
                        />
                    )}
                    {item.resourceType_Name ===
                        'ProtectedApplicationResource' && (
                        <Attribute
                            className={classes.horizontalOrVertical}
                            label={t('Common_AccessLevel')}
                            value={item.resourceTypeRole_FriendlyName}
                        />
                    )}
                    {item?.computerAdditionalProperties && (
                        <>
                            {item?.computerAdditionalProperties
                                ?.requestType && (
                                <Fragment>
                                    <Attribute
                                        padding={'0 0 1.6rem 0'}
                                        className={classes.horizontalOrVertical}
                                        label={t('Common_RequestType')}
                                        value={
                                            item?.computerAdditionalProperties
                                                ?.requestType
                                        }
                                    />
                                </Fragment>
                            )}
                            <Divider />
                            <Attribute
                                padding={'1.6rem 0'}
                                className={classes.horizontalOrVertical}
                                label={t('Common_TypeOfAccess')}
                                value={
                                    item?.computerAdditionalProperties
                                        ?.typeOfAccess
                                        ? item?.computerAdditionalProperties
                                              ?.typeOfAccess
                                        : '-'
                                }
                            />
                            {item?.computerAdditionalProperties
                                ?.personalCredentialId && (
                                <>
                                    <Divider />
                                    <Box display="flex">
                                        <Attribute
                                            padding={'1.6rem 0'}
                                            className={
                                                classes.horizontalOrVertical
                                            }
                                            label={t('CredentialType')}
                                            value={
                                                item
                                                    ?.computerAdditionalProperties
                                                    ?.credentialType
                                                    ? item
                                                          ?.computerAdditionalProperties
                                                          ?.credentialType
                                                    : '-'
                                            }
                                        />
                                        <Attribute
                                            padding={'1.6rem 3.4rem'}
                                            className={
                                                classes.horizontalOrVertical
                                            }
                                            label={t('Credential')}
                                            value={
                                                item
                                                    ?.computerAdditionalProperties
                                                    ?.credentialFriendlyName
                                                    ? item
                                                          ?.computerAdditionalProperties
                                                          ?.credentialFriendlyName
                                                    : '-'
                                            }
                                        />
                                    </Box>
                                </>
                            )}
                            {item.resourceTypeRole_FriendlyName && (
                                <>
                                    <Divider />
                                    <Attribute
                                        padding={'1.6rem 0'}
                                        className={classes.horizontalOrVertical}
                                        label={t('Common_AccessLevel')}
                                        value={
                                            item.resourceTypeRole_FriendlyName
                                        }
                                    />
                                </>
                            )}
                        </>
                    )}
                    {item.additionalFields.length > 0 && (
                        <AdditionalFieldsSection
                            item={item}
                            classes={classes}
                        />
                    )}
                    {item?.fieldTypeValueJson &&
                        hasValidJson(
                            updateJsonMappingWithApiFormat(
                                JSON.parse(item.fieldTypeValueJson),
                                false,
                            ),
                        ) && (
                            <FieldTypeValuesAssignmentInfo
                                fieldTypeValueJson={updateJsonMappingWithApiFormat(
                                    getParsedObject(
                                        JSON.parse(item.fieldTypeValueJson),
                                    ),
                                    false,
                                )}
                            />
                        )}
                </Box>
            </Box>
            {riskGroupedByLocalRiskId &&
                riskGroupedByLocalRiskId.length > 0 && (
                    <>
                        <Divider />
                        <Box className={classes.cartItemContent}>
                            {riskGroupedByLocalRiskId.map((r, index) => (
                                <Box key={`${r.id}_${index}`} py={'0.4rem'}>
                                    <CartItemViolation
                                        risk={r}
                                        showReason={true}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </>
                )}

            <Box
                className={classNames(classes.borderBox, {
                    [classes.riskBorderBox]:
                        riskGroupedByLocalRiskId &&
                        riskGroupedByLocalRiskId.length > 0,
                })}
            />
        </Card>
    )
}

const Attribute = ({ label, value, ...rest }) => {
    const theme = useTheme()

    return (
        <Box {...rest}>
            <Box
                color={theme?.palette?.grey?.[900]}
                fontSize="1.2rem"
                style={{ textTransform: 'uppercase' }}
            >
                {label}
            </Box>
            <Box fontSize="1.4rem">{value}</Box>
        </Box>
    )
}

const AdditionalFieldsSection = ({ item, classes }) => {
    const { t } = useTranslation()
    const buttonClasses = useShowMoreButtonStyles()
    const [showMore, setShowMore] = useState(false)
    return (
        <>
            <Divider />
            <Box style={{ position: 'relative' }}>
                <Button
                    onClick={() => setShowMore(!showMore)}
                    classes={buttonClasses}
                    endIcon={
                        <Icon
                            name="Arrow"
                            direction={showMore ? 'up' : 'down'}
                            style={{ width: '1.6rem', height: '1.6rem' }}
                            color="#2471ab"
                        />
                    }
                >
                    {showMore ? t('Common_ShowLess') : t('Common_ShowMore')}
                </Button>
                <Attribute
                    padding={'2.3rem 0 0.8rem 0'}
                    className={classes.horizontalOrVertical}
                    label={t('Common_DynamicRequestAttributes')}
                />
                {showMore && (
                    <Box display="flex">
                        <Box>
                            <Icon
                                name="dynamicForm"
                                height="4.5rem"
                                width="4.5rem"
                            />
                        </Box>
                        <Box style={{ marginLeft: '3.4rem' }}>
                            <Grid container spacing={1}>
                                {item.additionalFields.map((field, index) => (
                                    <Grid item xs={6}>
                                        <AdditionalField
                                            key={index}
                                            className={classes.additionalField}
                                            label={
                                                field.additionalField_LocaleKey
                                                    ? t(
                                                          `${NS}:MsCommonAnonymous_${field.additionalField_LocaleKey}`,
                                                      )
                                                    : field.additionalField_Name
                                            }
                                            value={field.additionalField_Value}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Box>
                )}
            </Box>
        </>
    )
}

const AdditionalField = ({ label, value, ...rest }) => {
    return (
        <Box {...rest}>
            <Box fontWeight="bold">{label}</Box>
            <Box color="#5d6870" fontSize="1.2rem">
                {value}
            </Box>
        </Box>
    )
}

CartItem.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number,
    handleDelteItem: PropTypes.func,
}

export default CartItem
