import {
    queryCache,
    useMutation,
    usePaginatedQuery,
    useQuery,
} from 'react-query'
import { useAxios } from 'packages/core'
import { useNotification } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { useQuery as packageQuery } from 'packages/core'
import moment from 'moment'
import { useHistory } from 'react-router'
import { useResourceTypeContext } from 'resourceTypeContext'

export const COMPUTER_KEY_PREFIX = 'COMPUTERS'

export const useComputer = (id, targetPersonId, isAssignmentId = false) => {
    const callApi = useAxios()

    let url = `/api/computers/${id}?targetPersonId=${targetPersonId}`

    if (isAssignmentId) {
        url = url + `&isAssignmentId=true`
    }

    return useQuery(
        [COMPUTER_KEY_PREFIX, id, targetPersonId],
        () =>
            callApi({
                method: 'GET',
                url,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useComputerOwners = (id) => {
    const callApi = useAxios()
    return useQuery(
        [`${COMPUTER_KEY_PREFIX}_OWNERS`, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/People/resourceOwners?resourceId=${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useComputerBr = (
    targetPersonId,
    resourceId,
    resourceTypeId,
    resourceAssignmentId,
) => {
    const callApi = useAxios()
    return useQuery(
        [
            COMPUTER_KEY_PREFIX,
            'Business_Request',
            targetPersonId,
            resourceId,
            resourceTypeId,
            resourceAssignmentId,
        ],
        () =>
            callApi({
                method: 'GET',
                url: `/api/computers/businessRequestItemAssignmentDetails?targetPersonId=${targetPersonId}&resourceId=${resourceId}&resourceTypeId=${resourceTypeId}&resourceAssignmentId=${resourceAssignmentId}`,
            }).then((data) => data.data),
        {
            enabled:
                Boolean(targetPersonId) &&
                Boolean(resourceId) &&
                Boolean(resourceTypeId) &&
                Boolean(resourceAssignmentId),
        },
    )
}

export const useCheckComputerAccess = (id, targetPersonId) => {
    const callApi = useAxios()
    return useQuery(
        [`${COMPUTER_KEY_PREFIX}_ACCESS`, id, targetPersonId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/Computers/checkAssigmentStatus/${id}/${targetPersonId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id) && Boolean(targetPersonId),
        },
    )
}

export const useCheckComputerAccessByAssignmentId = (
    assignmentId,
    targetPersonId,
) => {
    const callApi = useAxios()
    return useQuery(
        [`${COMPUTER_KEY_PREFIX}_ACCESS`, assignmentId, targetPersonId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/Computers/CheckAssigmentStatusByAssignment/${assignmentId}/${targetPersonId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(assignmentId) && Boolean(targetPersonId),
        },
    )
}

export const refreshComputerList = () => {
    queryCache.invalidateQueries((c) =>
        c.queryKey.includes('/api/computers/loginSessionAccess'),
    )
}

export const useConnectComputer = () => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: 'api/Credentials/checkOutForConnectNow',
                data,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('ComputerConnected'))
                refreshComputerList()
            },
            onError: () => {
                showWarningMessage(t('ComputerNotConnected'))
            },
        },
    )
}
export const useExtendComputerSession = () => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (data) =>
            callApi({
                method: 'PATCH',
                url: `api/Computers/extend/${data?.externalCredentialCheckoutId}?endDate=${data?.endDate}`,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('ComputerSessionExtended'))
                refreshComputerList()
            },
            onError: () => {
                showWarningMessage(t('ComputerSessionNotExtended'))
            },
        },
    )
}

export const useConnectComputerAdvance = () => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: 'api/Credentials/checkOutForConnectAdvance',
                data,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('ComputerConnected'))
                refreshComputerList()
            },
            onError: () => {
                showWarningMessage(t('ComputerNotConnected'))
            },
        },
    )
}

export const GetComputerPersonalCredentials = () => {
    const callApi = useAxios()
    return useQuery([`${COMPUTER_KEY_PREFIX}_CREDENTIALS`], () =>
        callApi({
            method: 'GET',
            url: `/api/Credentials/personalCredentials`,
        }).then((data) => data.data),
    )
}

export const useComputerSessionRequest = () => {
    const callApi = useAxios()
    return useMutation((data) =>
        callApi({
            method: 'POST',
            url: '/api/Computers/sessionRequests',
            data: {
                ...data,
                startDate: moment(data.startDate)
                    .startOf('day')
                    .set('millisecond', 0),
                endDate: moment(data.endDate)
                    .endOf('day')
                    .set('millisecond', 0),
            },
        }).then((data) => data),
    )
}

export const useComputerBookNow = () => {
    const { t } = useTranslation()
    const { showWarningMessage } = useNotification()
    const callApi = useAxios()

    return useMutation((data) =>
        callApi({
            method: 'POST',
            url: '/api/Credentials/checkOutForConnectAdvance',
            data: data,
        })
            .then((data) => data.data)
            .catch((err) => {
                if (
                    err &&
                    err.response &&
                    err.response.data &&
                    err.response.data.statusMessage
                ) {
                    if (
                        err.response.data.statusMessage.indexOf(
                            'time request exceeded max allow duration',
                        )
                    ) {
                        showWarningMessage(t('Common_TimeExceedErrorComputer'))
                    }
                } else {
                    showWarningMessage(t('Common_ErrorBookiingComputer'))
                }
            }),
    )
}

export const useComputerLoginSessionHistoryDetail = (id) => {
    const callApi = useAxios()
    return useQuery(
        [`${COMPUTER_KEY_PREFIX}_LOGIN_HISTORY_SESSION`, id],
        () =>
            callApi({
                method: 'GET',
                url: `api/Computers/loginSessionHistory/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useComputerLoginSessionAccessDetail = (id) => {
    const callApi = useAxios()
    return useQuery(
        [`${COMPUTER_KEY_PREFIX}_LOGIN_SESSION_DETAILS`, id],
        () =>
            callApi({
                method: 'GET',
                url: `api/Computers/loginSessionAccess/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useHasAccessToViewPSMRecordings = (
    checkedOutByPersonId,
    enabled,
) => {
    const callApi = useAxios()
    return useQuery(
        [COMPUTER_KEY_PREFIX, 'CAN_SEE_PSM_RECORDING', checkedOutByPersonId],
        () =>
            callApi({
                method: 'GET',
                url: `api/Computers/HasAccessToViewPSMRecordings/${checkedOutByPersonId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(enabled),
            staleTime: Infinity,
        },
    )
}

export const useHasAccessToViewSessionEvents = (
    checkedOutByPersonId,
    enabled,
) => {
    const callApi = useAxios()
    return useQuery(
        [COMPUTER_KEY_PREFIX, 'CAN_SEE_SESSION_EVENTS', checkedOutByPersonId],
        () =>
            callApi({
                method: 'GET',
                url: `api/Computers/HasAccessToViewSessionEvents/${checkedOutByPersonId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(enabled),
            staleTime: Infinity,
        },
    )
}

export const useComputerLiveSessionToken = (
    id,
    width,
    height,
    dpi,
    enabled,
) => {
    const callApi = useAxios()
    return useQuery(
        [`${COMPUTER_KEY_PREFIX}_LOGIN_HISTORY_SESSION_LIVE`, id],
        () =>
            callApi({
                method: 'GET',
                url: `api/Computers/joinSession/${id}?width=${width}&height=${height}&dpi=${dpi}`,
            }).then((data) => data.data),
        {
            enabled,
        },
    )
}

export const useComputerLoginSessionEvents = (id, skip, take) => {
    const [state] = useResourceTypeContext()

    const queryData = {
        skip,
        take,
    }

    if (state.searchTerm) {
        queryData.searchTerm = state.searchTerm
    }

    if (state.sorting.sortOrder) {
        queryData.sortBy = state.sorting.sortBy
        queryData.desc = state.sorting.sortOrder === 'desc'
    }

    const callApi = useAxios()
    return usePaginatedQuery(
        [COMPUTER_KEY_PREFIX, id, queryData],
        () =>
            callApi({
                method: 'POST',
                url: `api/Computers/sessionEvents/${id}`,
                data: queryData,
            }),
        {
            enabled: Boolean(id),
        },
    )
}

export const useComputerPSMSessionSSHLogs = (id) => {
    const callApi = useAxios()
    const { t } = useTranslation()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    return useMutation(
        () =>
            callApi({
                method: 'GET',
                url: `api/computers/sshlogs/${id}`,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('FileDownloading'))
            },
            onError: (error) => {
                showWarningMessage(error.response.data)
            },
        },
    )
}

export const useComputerCheckIn = () => {
    const callApi = useAxios()
    const { t } = useTranslation()
    const query = packageQuery()
    const history = useHistory()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    return useMutation(
        (credentialsCheckOutId) =>
            callApi({
                method: 'POST',
                url: `api/credentials/CheckIn/${credentialsCheckOutId}`,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('Comon_ComputerCheckedIn'))
                refreshComputerList()
                query.delete('selected')
                history.push(`${history.location.pathname}?${query.toString()}`)
            },
            onError: () => {
                showWarningMessage(t('Common_ErrorCheckinComputer'))
            },
        },
    )
}

export const useComputerLoginSessionHistoryDetails = (queryParams) => {
    const callApi = useAxios()
    return useQuery(
        [`${COMPUTER_KEY_PREFIX}_LOGIN_HISTORY_SESSION`, queryParams],
        () =>
            callApi({
                method: 'GET',
                url: `api/Computers/loginSessionHistory/${queryParams}`,
            }).then((data) => data),
        {
            enabled: Boolean(queryParams),
        },
    )
}

export const useTerminateSession = () => {
    const callApi = useAxios()
    const { t } = useTranslation()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    return useMutation(
        (privilegedSessionId) =>
            callApi({
                method: 'PATCH',
                url: `/api/Computers/loginSessionHistory/terminate/${privilegedSessionId}`,
            }),
        {
            onSuccess: () => {
                refreshComputerSessionList()
                showSuccessMessage(t('Comon_ComputerTerminateSessionSuccess'))
            },
            onError: () => {
                showWarningMessage(t('Comon_ComputerTerminateSessionFailure'))
            },
        },
    )
}

export const refreshComputerSessionList = () => {
    queryCache.invalidateQueries((c) =>
        c.queryKey.includes('/api/computers/loginSessionHistory'),
    )
}

export const GetComputerSharedCredentials = (computerId) => {
    const callApi = useAxios()
    return useQuery(
        [`${COMPUTER_KEY_PREFIX}_SHARED_CREDENTIALS_${computerId}`],
        () =>
            callApi({
                method: 'GET',
                url: `/api/Computers/computerSharedCredentialsInfo?computerId=${computerId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(computerId),
        },
    )
}

export const GetDisabledTimeSlots = (credentialId, endDate) => {
    const callApi = useAxios()
    return useQuery(
        [`${COMPUTER_KEY_PREFIX}_BOOKED_SLOTS_${credentialId}`],
        () =>
            callApi({
                method: 'POST',
                url: `/api/Credentials/bookedDateTimeSlots/${credentialId}?endDateBefore=${endDate}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(credentialId),
        },
    )
}
