import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useAuthState } from '../../core/auth/context'
import ProgressBar from '../ProgressBar'
import { getWorkflowWaitTimeInSeconds } from '../../core/helpers'
import { useHistory } from 'react-router'
import LoaderGif from '../assets/WorkflowIframe-background-loader.gif'
import appConfig from 'config'

const getIframeStyles = (height?: string) => ({
    border: 'none',
    width: '100%',
    height: height ? height : '78vh',
    margin: 0,
    padding: 0,
    backgroundImage: `url(${appConfig.APP_SUBPATH}${LoaderGif})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
})

export type WorkflowIFrameProps = {
    baseEidUrl: string
    workflowUrl?: string
    workflowName?: string
    workflowParams?: string[]
    onComplete: () => void
    height?: string
    loadingMessage?: string
}

export const WorkflowIFrame = (props: WorkflowIFrameProps) => {
    const {
        baseEidUrl,
        onComplete,
        workflowUrl,
        workflowName,
        workflowParams,
        height,
        loadingMessage,
        ...rest
    } = props
    const [{ isWorkflowIRenderable }]: any = useAuthState()
    const workflowWaitTime = getWorkflowWaitTimeInSeconds()
    const progressIncrement = 100 / workflowWaitTime
    const [progress, setProgress] = useState(progressIncrement)
    let progressInterval: any = null

    const history = useHistory()

    useEffect(() => {
        const eventListener = (e: WindowEventMap['message']) => {
            if (e.origin === baseEidUrl) {
                if (e.data.type === 'SessionEnded') {
                    console.log(e.data) // keeping logs so we know the event occured
                    history.push('/signout')
                }
                if (e.data.type === 'WorkflowEnded') {
                    console.log(e.data) // keeping logs so we know the event occured
                    setTimeout(onComplete, 3000)
                } else if (e.data.type === 'WorkflowCanceled') {
                    console.log(e.data) // keeping logs so we know the event occured
                    onComplete()
                }
            }
        }

        window.addEventListener('message', eventListener)

        return () => {
            window.removeEventListener('message', eventListener)
        }
    }, [])
    useEffect(() => {
        if (!isWorkflowIRenderable && progress < 100) {
            progressInterval = setInterval(() => {
                setProgress((pre) => Math.round(pre + progressIncrement))
            }, 1000)
        }
        return () => {
            clearInterval(progressInterval)
        }
    }, [progress])

    if (!isWorkflowIRenderable && workflowName) {
        return (
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '80px',
                    width: '100%',
                }}
            >
                <Box style={{ width: '200px' }}>
                    <ProgressBar
                        label={loadingMessage}
                        completed={progress}
                        height={16}
                        showPercentage={false}
                        showAnimation={true}
                    />
                </Box>
            </Box>
        )
    }

    let fullWorkflowUrl

    if (workflowUrl) {
        fullWorkflowUrl = workflowUrl
    } else {
        fullWorkflowUrl = constructApprovalWorkflowUrl(
            baseEidUrl,
            workflowName,
            workflowParams,
        )
    }

    return (
        <iframe
            title={`EmpowerID ${workflowName}`}
            style={workflowName ? getIframeStyles(height) : { display: 'none' }}
            src={fullWorkflowUrl}
            {...rest}
        />
    )
}

const constructApprovalWorkflowUrl = (
    baseEidUrl?: string,
    workflowName?: string,
    workflowParams?: string[],
) => {
    let queryParams = ['popupNav=true']

    if (workflowParams) {
        queryParams = queryParams.concat(workflowParams)
    }

    const queryString = queryParams.join('&')

    return `${baseEidUrl}/ui?hideHeader=true&wfMsContext=true&wfMsUrl=${window.location.origin}#w/${workflowName}?${queryString}`
}
