import { Box, Button, Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTargetPerson, useTreeFilterAPI } from 'hooks'
import { TREE_VIEW_LEVELS } from 'utils'
import TreeNode from './components/TreeWithSearch/TreeNode'
import { ThresholdSearchInput, Tooltip } from 'packages/eid-ui'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import { useTranslation } from 'react-i18next'
import { useGetControlsAccess } from 'packages/core'
import { useResourceTypeContext } from 'resourceTypeContext'
import config from 'config'

const TreeViewWithSearch = ({
    url,
    showSearch = true,
    value,
    onChange,
    queryParam,
    searchPlaceholder,
    highlightText,
    toggleExpand,
    isExpanded,
    ...rest
}) => {
    const controls = useGetControlsAccess()
    const [targetPerson] = useTargetPerson()
    const [{ showSuggested }] = useResourceTypeContext()
    const [selectedTreeValue, setSelectedTreeValue] = useState(value)
    const [search, setSearch] = useState()
    const { t } = useTranslation()

    let basicUrl = `${url}?targetPersonId=${targetPerson.id}`
    let showSuggestedToPass = Boolean(showSuggested)

    if (rest.name === 'ApplicationProcesses') {
        const hasAccessToSuggestedApplicationRolesControl =
            controls.findIndex(
                (c) => c.name === 'ITShop-SuggestedApplicationRoles-Control',
            ) >= 0

        if (
            !hasAccessToSuggestedApplicationRolesControl &&
            config &&
            config.FETCH_SUGGESTED_APPLICATION_ROLES_ALWAYS?.toLowerCase() ===
                'true'
        ) {
            showSuggestedToPass = true
        }
    }

    const { data, isFetching } = useTreeFilterAPI(
        basicUrl,
        rest?.parentId,
        undefined,
        search,
        TREE_VIEW_LEVELS,
        true,
        showSuggestedToPass,
    )

    useEffect(() => {
        setSelectedTreeValue(value)
    }, [value])

    useEffect(() => {
        onChange(selectedTreeValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTreeValue])

    return (
        <Box>
            {/* Autocomplete Section */}

            <Box
                sx={{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'flex-end',
                }}
            >
                {showSearch && (
                    <Box sx={{ width: '100%' }}>
                        <ThresholdSearchInput
                            placeholder={searchPlaceholder || 'Common_Search'}
                            handleSearch={(val) => setSearch(val)}
                        />
                    </Box>
                )}
                <Tooltip title={t(isExpanded ? 'Collapse' : 'Expand')}>
                    <Button
                        onClick={toggleExpand}
                        sx={{
                            background: '#fff !important',

                            borderRadius: '.5rem',
                            padding: '1rem',
                            border: 'solid 1px #ebebed',
                            minWidth: 'auto',
                            height: '4.5rem',
                        }}
                    >
                        {isExpanded ? (
                            <CloseFullscreenIcon />
                        ) : (
                            <OpenInFullIcon />
                        )}
                    </Button>
                </Tooltip>
            </Box>
            {/* Tree Section */}
            <Box
                sx={{
                    marginTop: '2rem',
                    height: 'auto',
                    minHeight: '4rem',
                    maxHeight: '40rem',
                    overflow: 'auto',
                    marginLeft: '1.5rem',
                }}
            >
                {isFetching ? (
                    <Box>
                        <Skeleton height={20} />
                        <Skeleton height={20} />
                        <Skeleton height={20} />
                        <Skeleton height={20} />
                    </Box>
                ) : (
                    <TreeNode
                        handleSelect={(val) => {
                            setSelectedTreeValue(val)
                        }}
                        explorer={data || {}}
                        value={selectedTreeValue?.[queryParam?.valueProp]}
                        highlightText={highlightText || search}
                        titleProp={'friendlyName'}
                        valueProp={queryParam?.valueProp}
                        url={basicUrl}
                        showSuggestedToPass={showSuggestedToPass}
                        showCollapseIcon={true}
                    />
                )}
            </Box>
        </Box>
    )
}

export default TreeViewWithSearch
