import React from 'react'
import TreeNode from './TreeNode'

const ChildTreeNode = React.memo(
    ({
        item,
        handleSelect,
        value,
        highlightText,
        disableAssignableCheck,
        url,
        titleProp,
        valueProp,
        showRadioToSelect,
        showCollapseIcon,
        onChildrenUpdate,
        showSuggestedToPass
    }) => {
        return (
            <TreeNode
                explorer={item}
                handleSelect={handleSelect}
                value={value}
                disableAssignableCheck={disableAssignableCheck}
                highlightText={highlightText}
                url={url}
                titleProp={titleProp}
                valueProp={valueProp}
                showRadioToSelect={showRadioToSelect}
                showCollapseIcon={showCollapseIcon}
                onChildrenUpdate={onChildrenUpdate}
                showSuggestedToPass={showSuggestedToPass}
            />
        )
    },
)

export default ChildTreeNode
