import React from 'react'
import { useTheme, Box, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Spinner, theme } from 'packages/eid-ui'

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '5px',
        width: props.width,
        height: props.height,
        paddingLeft: '8px',
        paddingRight: '8px',
        backgroundColor: props.disabled ? props.disabledColor : props.bgColor,
        border: props.border,
        color: props?.fontColor
            ? props.fontColor
            : theme?.palette?.common?.white,
        opacity: props.disabled ? 0.8 : 1,
        minWidth: props.minWidth,
        '&:hover': {
            cursor: props.disabled || props.loading ? 'initial' : 'pointer',
            backgroundColor: props.hoverColor,
        },
    }),
}))

const ActionButton = (props) => {
    const theme = useTheme()

    const {
        width,
        height,
        bgColor,
        hoverColor,
        border,
        fontColor,
        children,
        loading,
        onClick,
        disabled,
        loaderColor,
        disabledColor = theme?.palette?.disabled?.main,
        minWidth = '120px',
    } = props
    const classes = useStyles({
        width,
        height,
        bgColor,
        border,
        fontColor,
        hoverColor,
        disabled,
        disabledColor,
        minWidth,
        loading,
    })

    return (
        <Link
            component="button"
            underline="none"
            onClick={disabled || loading ? undefined : onClick}
            style={{ width: width ? width : '100%', minWidth: minWidth }}
        >
            <Box className={classes.root}>
                {loading && <Spinner size={0.8} color={loaderColor} />}
                {!loading && children}
            </Box>
        </Link>
    )
}

ActionButton.defaultProps = {
    width: '100px',
    height: '48px',
    bgColor: theme?.palette?.common?.white,
    border: 'none',
    loaderColor: theme?.palette?.common?.white,
}

export default ActionButton
