import React, { Fragment, useEffect, useState } from 'react'
import { ArrowSmallIcon, Icon } from 'packages/eid-icons'
import {
    Box,
    Radio,
    Skeleton,
    styled,
    Typography,
    useTheme,
} from '@mui/material'
import { getFullImageUrl, TREE_VIEW_LEVELS } from 'utils'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useTreeFilterAPI } from 'hooks'
import ChildTreeNode from './ChildTreeNode'
import { Tooltip } from 'packages/eid-ui'

const FolderMain = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.4rem',
    '& img': {
        width: '1.5rem',
        marginLeft: '0.5rem',
        cursor: 'pointer',
    },
})

const BusinesRoleAndLocationTreeBox = styled(Box)({
    marginTop: '1rem',
    position: 'relative',
    '& p': {
        fontSize: '1.4rem',
        marginLeft: '0.8rem',
        fontWeight: '600',
        color: '#767676',
    },
})

const FolderInnerItem = styled(Box)({
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 0.9rem 0.5rem 0.8rem',
    borderRadius: '0.5rem',
    '& img': {
        width: '1.5rem',
        marginLeft: '0.5rem',
    },
    '& p': {
        fontSize: '1.4rem',
        marginLeft: '0.8rem',
        cursor: 'pointer',
    },
})

const SelectSection = styled(Box)({
    maxWidth: '30rem',
    '& p': {
        fontWeight: '500',
    },
})

const SelectedNode = styled(Box)(({ theme }) => ({
    background: '#3d3d44',
    padding: '0 0.3rem',
    borderRadius: '0.4rem',
    color: `${theme?.palette?.common?.white} !important`,
}))

const NodeLabel = styled(Typography)(({ theme }) => ({
    cursor: 'pointer',
    '& .search-highlight': {
        color: '#d36dbf',
        fontWeight: 600,
        textDecoration: 'underline',
    },
}))

const StyledRadio = styled(Radio)({
    padding: '0.2rem',
    maxHeight: '2rem',
    '&:hover': {
        backgroundColor: '#d2d2d9',
    },
})

const highlight = (text, searchKey) => {
    searchKey = new RegExp('(' + searchKey + ')', 'gi')
    return text.split(searchKey).map((o, i) =>
        i % 2 === 1 ? (
            <span className="search-highlight" key={i}>
                {o}
            </span>
        ) : (
            o
        ),
    )
}

const TreeNode = React.memo(
    ({
        explorer,
        handleSelect,
        value,
        disableAssignableCheck = false,
        highlightText = '',
        titleProp = 'friendlyName',
        valueProp = 'id',
        url,
        showCollapseIcon = true,
        showRadioToSelect = true,
        disableAssignableProp = 'isAssignable',
        onChildrenUpdate,
        showSuggestedToPass
    }) => {
        const { t } = useTranslation()
        const [isOpen, setIsOpen] = useState(false)
        const [fetchChildren, setFetchChildren] = useState(false)
        const [children, setChildren] = useState(explorer?.children || [])
        const theme = useTheme()

        useEffect(() => {
            setChildren(explorer?.children || [])
        }, [explorer])

        useEffect(() => {
            if (
                highlightText?.length >= 3 &&
                !isOpen &&
                explorer?.hasChildren &&
                explorer?.children?.length
            ) {
                setIsOpen(true)
            }
        }, [highlightText])

        const { data, isFetching } = useTreeFilterAPI(
            url,
            explorer?.id,
            explorer?.id,
            '',
            TREE_VIEW_LEVELS,
            fetchChildren,
            showSuggestedToPass
        )

        useEffect(() => {
            if (data && explorer?.id) {
                if (onChildrenUpdate) {
                    onChildrenUpdate(explorer.id, data?.children)
                } else {
                    setChildren(data?.children)
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [data])

        const handleToggle = () => {
            setIsOpen(!isOpen)
            if (!isOpen) {
                if (
                    explorer.hasChildren &&
                    children.length === 0 &&
                    !isFetching
                ) {
                    setFetchChildren(!fetchChildren)
                }
            }
        }

        return (
            <Fragment>
                {explorer?.hasChildren ? (
                    <BusinesRoleAndLocationTreeBox>
                        <FolderMain>
                            <Box
                                onClick={handleToggle}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    gap: showRadioToSelect ? '1rem' : '0',
                                }}
                            >
                                {explorer.hasChildren ? (
                                    isOpen ? (
                                        showCollapseIcon ? (
                                            <Box
                                                sx={{
                                                    '&:before': {
                                                        content: '""',
                                                        position: 'absolute',
                                                        width: '.12rem',
                                                        backgroundColor:
                                                            'transparent',
                                                        borderLeft:
                                                            '1px dotted lightgrey',
                                                        top: '1.7rem',
                                                        bottom: '1rem',
                                                        left: '.7rem',
                                                        transform:
                                                            'translateX(-50%)',
                                                    },
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        height: '1.5rem',
                                                        '&:before': {
                                                            content: '""',
                                                            position:
                                                                'absolute',
                                                            width: '.12rem',
                                                            backgroundColor:
                                                                'transparent',
                                                            borderLeft:
                                                                '1px dotted lightgrey',
                                                            top: '-.8rem',
                                                            bottom: '1rem',
                                                            left: '.7rem',
                                                            transform:
                                                                'translateX(-50%)',
                                                            height: '.8rem',
                                                        },
                                                        '&:after': {
                                                            content: '""',
                                                            position:
                                                                'absolute',
                                                            width: '.8rem',
                                                            backgroundColor:
                                                                'transparent',
                                                            borderTop:
                                                                '1px dotted lightgrey',
                                                            top: '1rem',
                                                            left: '2.2rem',
                                                            transform:
                                                                'translateX(-50%)',
                                                            height: '.12rem',
                                                        },
                                                    }}
                                                >
                                                    <Icon name="Collapse" />
                                                </Box>
                                            </Box>
                                        ) : (
                                            <ArrowSmallIcon
                                                color="#60708f"
                                                fill="#60708f"
                                                direction={'down'}
                                            />
                                        )
                                    ) : showCollapseIcon ? (
                                        <Box
                                            sx={{
                                                height: '1.5rem',
                                                '&:before': {
                                                    content: '""',
                                                    position: 'absolute',
                                                    width: '.12rem',
                                                    borderLeft:
                                                        '1px dotted grey',
                                                    top: '-.8rem',
                                                    bottom: '1rem',
                                                    left: '.7rem',
                                                    transform:
                                                        'translateX(-50%)',
                                                    height: '.8rem',
                                                },
                                                '&:after': {
                                                    content: '""',
                                                    position: 'absolute',
                                                    width: '.8rem',
                                                    borderTop:
                                                        '1px dotted grey',
                                                    top: '1rem',
                                                    bottom: '1rem',
                                                    left: '2.2rem',
                                                    transform:
                                                        'translateX(-50%)',
                                                    height: '.12rem',
                                                },
                                            }}
                                        >
                                            <Icon name="Expand" />
                                        </Box>
                                    ) : (
                                        <ArrowSmallIcon
                                            color="#60708f"
                                            fill="#60708f"
                                            direction={'right'}
                                        />
                                    )
                                ) : null}
                                {(explorer?.imageUrl || explorer?.iconUrl) && (
                                    <img
                                        alt="folder-icons"
                                        src={getFullImageUrl(
                                            explorer?.imageUrl ||
                                                explorer?.iconUrl,
                                        )}
                                    />
                                )}
                                {explorer?.icon && (
                                    <Icon
                                        name={explorer.icon}
                                        width="1.6rem"
                                        height="1.6rem"
                                        color={theme?.palette?.primary?.main}
                                    />
                                )}
                                {showRadioToSelect && (
                                    <StyledRadio
                                        checkedIcon={
                                            <Icon
                                                name="RadioFilled"
                                                color={
                                                    theme?.palette?.primary
                                                        ?.main
                                                }
                                            />
                                        }
                                        icon={<Icon name="Radio" />}
                                        checked={
                                            value === explorer?.[valueProp] ??
                                            false
                                        }
                                        disabled={
                                            explorer?.[
                                                disableAssignableProp
                                            ] === false &&
                                            disableAssignableCheck
                                        }
                                        onClick={() => handleSelect(explorer)}
                                    />
                                )}
                            </Box>
                            <Tooltip
                                title={
                                    explorer?.alreadyAssigned
                                        ? t('AlreadyAssigned')
                                        : explorer?.isAssignable === false &&
                                          disableAssignableCheck
                                        ? t('NotAvailableForAssignment')
                                        : ''
                                }
                            >
                                <NodeLabel
                                    className={classNames({
                                        selectedNode:
                                            explorer?.[valueProp] &&
                                            value === explorer?.[valueProp],
                                        isDisabled:
                                            explorer?.[
                                                disableAssignableProp
                                            ] === false &&
                                            disableAssignableCheck,
                                    })}
                                    onClick={() => {
                                        if (
                                            explorer?.[
                                                disableAssignableProp
                                            ] === true ||
                                            !disableAssignableCheck
                                        ) {
                                            handleSelect(explorer)
                                        }
                                    }}
                                >
                                    {highlightText && highlightText.length
                                        ? highlight(
                                              explorer?.[titleProp],
                                              highlightText,
                                          )
                                        : explorer?.[titleProp]}
                                </NodeLabel>
                            </Tooltip>
                        </FolderMain>

                        {isOpen && isFetching ? (
                            <Box sx={{ paddingLeft: '1.5rem' }}>
                                <Skeleton height={20} />
                                <Skeleton height={20} />
                            </Box>
                        ) : null}

                        {isOpen && children.length > 0 && (
                            <Fragment>
                                {children.map((item, index) => (
                                    <div
                                        style={{
                                            paddingLeft: '2.2rem',
                                        }}
                                        key={`${item?.id}-${index}`}
                                    >
                                        <ChildTreeNode
                                            item={item}
                                            handleSelect={handleSelect}
                                            value={value}
                                            disableAssignableCheck={
                                                disableAssignableCheck
                                            }
                                            highlightText={highlightText}
                                            url={url}
                                            titleProp={titleProp}
                                            valueProp={valueProp}
                                            showRadioToSelect={
                                                showRadioToSelect
                                            }
                                            showCollapseIcon={showCollapseIcon}
                                            disableAssignableProp={
                                                disableAssignableProp
                                            }
                                            onChildrenUpdate={onChildrenUpdate}
                                            showSuggestedToPass={showSuggestedToPass}
                                        />
                                    </div>
                                ))}
                            </Fragment>
                        )}
                    </BusinesRoleAndLocationTreeBox>
                ) : explorer?.[valueProp] && explorer?.[titleProp] ? (
                    <FolderInnerItem className={SelectSection}>
                        {(explorer?.imageUrl || explorer?.iconUrl) && (
                            <img
                                alt="folder-icons"
                                src={getFullImageUrl(
                                    explorer?.imageUrl || explorer?.iconUrl,
                                )}
                            />
                        )}
                        {showRadioToSelect && (
                            <Box
                                sx={{
                                    paddingLeft: '2.2rem',
                                }}
                            >
                                <StyledRadio
                                    checkedIcon={
                                        <Icon
                                            name="RadioFilled"
                                            color={
                                                theme?.palette?.primary?.main
                                            }
                                        />
                                    }
                                    icon={<Icon name="Radio" />}
                                    checked={
                                        value === explorer?.[valueProp] ?? false
                                    }
                                    disabled={
                                        explorer?.[disableAssignableProp] ===
                                            false && disableAssignableCheck
                                    }
                                    onClick={() => handleSelect(explorer)}
                                />
                            </Box>
                        )}
                        <Tooltip
                            title={
                                explorer?.alreadyAssigned
                                    ? t('AlreadyAssigned')
                                    : explorer?.isAssignable === false &&
                                      disableAssignableCheck
                                    ? t('NotAvailableForAssignment')
                                    : ''
                            }
                        >
                            <NodeLabel
                                className={classNames({
                                    selectedNode:
                                        explorer?.[valueProp] &&
                                        value === explorer?.[valueProp],
                                    isDisabled:
                                        explorer?.[disableAssignableProp] ===
                                            false && disableAssignableCheck,
                                })}
                                onClick={() => {
                                    if (
                                        explorer?.[disableAssignableProp] ===
                                            true ||
                                        !disableAssignableCheck
                                    ) {
                                        handleSelect(explorer)
                                    }
                                }}
                            >
                                {highlightText && highlightText.length
                                    ? highlight(
                                          explorer?.[titleProp],
                                          highlightText,
                                      )
                                    : explorer?.[titleProp]}
                            </NodeLabel>
                        </Tooltip>
                    </FolderInnerItem>
                ) : (
                    <Box>
                        <Typography
                            sx={{
                                textAlign: 'center',
                            }}
                        >
                            {t('Common_NoDataFound')}
                        </Typography>
                    </Box>
                )}
            </Fragment>
        )
    },
)

export default TreeNode
