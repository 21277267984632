import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    useCurrentPerson,
    useAccessRequestPolicy,
    useCart,
    useAddItemToCart,
    useExtendComputerSession,
} from 'hooks'
import {
    Tooltip,
    Attribute,
    AttributeLabel,
    AttributeValue,
    IconButton,
} from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { Box, Typography, useTheme, Dialog } from '@mui/material'
import { ItemDetails } from 'components'
import { useTimeConstrainedControl } from 'containers/ListingPage/Computers/TimeConstrainedControl'
import moment from 'moment'
import { CART_REQUEST_TYPES } from 'utils'

const ComputerExtendButton = ({ item, handleClose }) => {
    const resource = item.resource ? item.resource : item
    const { t } = useTranslation()
    const theme = useTheme()
    const { data: cart } = useCart()
    const { data: currentPerson } = useCurrentPerson()
    const requestPolicy = useAccessRequestPolicy(resource.requestPolicyId)
    const [addItemToCart, { isLoading: isAddingToCart }] = useAddItemToCart()
    const [extendSession, { isLoading: isExtending }] =
        useExtendComputerSession()

    const {
        selectedStartDate,
        selectedEndDate,
        hasInvalidDates,
        timeConstrainedControl,
    } = useTimeConstrainedControl(requestPolicy, true, false, false, true)

    const [open, setOpen] = useState(true)
    const [duration, setDuration] = useState(0)

    useEffect(() => {
        if (requestPolicy) {
            setDuration(requestPolicy.defaultValueInMinutes)
        }
    }, [requestPolicy])

    const handleExtend = () => {
        const payload = {
            externalCredentialCheckoutId: resource?.checkOutId,
            endDate: moment.utc(selectedEndDate).format(),
        }

        extendSession(payload)
            .then(() => {
                onClose()
            })
            .catch((err) => console.error(err))
    }

    const handleAddToCart = () => {
        const itemToAdd = {
            assignmentType: CART_REQUEST_TYPES.UpdateTimeConstrain,
            requestableResourceId: resource.id,
            resource_FriendlyName: resource.friendlyName,
            resourceTypeId: resource.resourceTypeId,
            resourceType_Name: resource.resourceTypeName,
            resourceTypeRoleId: resource?.checkedOutCredentialId,

            targetPersonId: currentPerson.id,
            timeConstraintActive: true,
            startDateUtc: selectedStartDate,
            endDateUtc: selectedEndDate,
        }
        addItemToCart(itemToAdd)
            .then(() => {
                onClose()
            })
            .catch((err) => console.error(err))
    }

    const onClose = () => {
        setOpen(false)
        handleClose()
    }

    const durationSelectorSection = (
        <Box style={{ position: 'relative' }}>
            {requestPolicy?.isTimeConstrained && (
                <Attribute orientation="horizontal">
                    <AttributeLabel
                        sx={{
                            minWidth: '220px',
                        }}
                    >
                        {t('Common_DefaultAccessDuration')}
                    </AttributeLabel>
                    <AttributeValue>
                        {requestPolicy && requestPolicy.defaultValueInMinutes}
                    </AttributeValue>
                </Attribute>
            )}

            {requestPolicy?.isTimeConstrained && (
                <Attribute orientation="horizontal">
                    <AttributeLabel
                        sx={{
                            minWidth: '220px',
                        }}
                    >
                        {t('Common_MaxAccessDuration')}
                    </AttributeLabel>
                    <AttributeValue>
                        {requestPolicy && requestPolicy.maximumValueInMinutes}
                    </AttributeValue>
                </Attribute>
            )}
            <Box>{timeConstrainedControl}</Box>
        </Box>
    )

    const getTooltipTitle = () => {
        const itemAlreadyInCart =
            !resource.isPreApproved &&
            cart?.cartItems.find(
                (c) => c.requestableResourceId === resource.id,
            ) !== undefined

        let message = ''
        if (duration < 1 || hasInvalidDates) {
            message = t('Common_InvalidDuration')
        }

        if (message === '' && itemAlreadyInCart) {
            message = t('Common_ItemAlreadyInCart')
        }
        return message
    }

    const renderAddToCartButton = () => {
        return (
            <Tooltip title={getTooltipTitle()}>
                <Box minWidth="167px">
                    <ItemDetails.ActionButton
                        fontColor={theme?.palette?.common?.white}
                        bgColor={theme?.palette?.primary?.main}
                        width="fit-content"
                        minWidth="100%"
                        loading={isAddingToCart || isExtending}
                        disabled={getTooltipTitle() !== ''}
                        onClick={
                            !resource.isPreApproved
                                ? handleAddToCart
                                : handleExtend
                        }
                    >
                        {!resource.isPreApproved ? (
                            <Box display="flex" alignItems="center">
                                <Icon
                                    name={'AddToCart'}
                                    color={theme?.palette?.common?.white}
                                />
                            </Box>
                        ) : (
                            <Box
                                display="flex"
                                alignItems="center"
                                marginLeft="10px"
                            >
                                <Typography
                                    style={{
                                        color: theme?.palette?.common?.white,
                                    }}
                                >
                                    {!resource.isPreApproved
                                        ? t('Common_AddToCart')
                                        : t('Submit')}
                                </Typography>
                            </Box>
                        )}
                    </ItemDetails.ActionButton>
                </Box>
            </Tooltip>
        )
    }

    return (
        <Dialog
            sx={{
                '& .MuiPaper-root': {
                    width: '100%',
                },
            }}
            scroll={'body'}
            open={open}
            onClose={() => {
                onClose()
            }}
            // aria-labelledby={title}
            // aria-describedby={title}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: '80px',
                    alignItems: 'center',
                    padding: '16px 12px 16px 32px',
                    backgroundColor: theme?.palette?.background?.paper,
                }}
            >
                <Typography
                    sx={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        color: '#3b454d',
                    }}
                >
                    {t('ExtendSession')}
                </Typography>

                <Box display="flex">
                    <IconButton
                        onClick={() => {
                            onClose()
                        }}
                        size="large"
                    >
                        <Icon name="Close" color="#959598" />
                    </IconButton>
                </Box>
            </Box>
            <Box
                sx={{
                    overflowWrap: 'break-word',
                    wordBreak: 'normal',
                    textTransform: 'uppercase',
                    color: `${theme?.palette?.common?.black} !important`,
                    overflow: 'hidden',
                    lineHeight: '15px',
                    fontSize: '14px',
                    padding: '15px 31px',
                    borderTop: '1px solid rgba(0, 0, 0, .05)',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                    fontWeight: 'bold',
                    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
                    backgroundImage:
                        'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
                    backgroundSize: '8.00px 8.00px',
                    '& svg': {
                        margin: '0px !important',
                    },
                }}
            >
                {t('SetDuration')}
            </Box>
            <Box
                sx={{
                    backgroundColor: '#fbfbfd',
                    padding: '24px 40px',
                    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
                    display: 'flex',
                    '&:focus': {
                        outline: 'none',
                    },
                }}
            >
                <Box marginTop={'2rem'}>{durationSelectorSection}</Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    padding: '16px 12px 16px 32px',
                    paddingTop: '16px',
                }}
            >
                {renderAddToCartButton()}
            </Box>
        </Dialog>
    )
}
export default ComputerExtendButton
