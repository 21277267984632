import React, { Fragment, useState } from 'react'
import { ItemDetails } from 'components'
import { Box, styled, Typography, useTheme } from '@mui/material'
import { DifferentiationValueIcon } from 'packages/eid-icons'
import { EidTree } from 'packages/eid-controls'
import { useIsSmallScreen } from 'packages/core'
import { useCheckAzureRbacRoleAccess, useTargetPerson } from 'hooks'
import { useTranslation } from 'react-i18next'
import cartHelpers from 'containers/Cart/cartHelpers'
import { GlobalSensitiveFunctions } from './GlobalSensitiveFunctions'
import { OverviewSection } from './OverviewSection'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { AddToCart } from 'components/AddToCart'
import { Skeleton } from '@mui/material'
import ActivateNowButton from 'containers/ListingPage/ActivateNowButton'
import useConfiguration from 'useConfiguration'
import AdditionalInformationSection from './MoreInformation'
import TextWithIcon from 'components/common/TextWithIcon'

const PaddedDiv = styled('div')({
    padding: '0 31px',
})

const PromptContainer = styled('div')({
    padding: '48px 31px',
    minHeight: '232px',
    display: 'flex',
})

const AzureRbacRoleDetails = ({ azureRbacRole, toggleDrawer }) => {
    const { t } = useTranslation()
    const theme = useTheme()

    const registry = useRegistry()

    const azureRbacRoleType = registry.get(
        ResourceTypesNamespace,
        'AzureRbacRoles',
    )
    const { getResourceType } = useConfiguration()

    const currentResourceType = getResourceType('AzureRoles')
    const isSmallScreen = useIsSmallScreen()

    const [activeTab, setActiveTab] = useState('overview')

    const [targetPerson] = useTargetPerson()
    const [location, setLocation] = useState(null)

    const {
        data: assignmentStatus,
        isLoading: isCheckingAccess,
    } = useCheckAzureRbacRoleAccess(azureRbacRole.id, location?.id)

    const preAdd = (baseObj) => {
        const itemToAdd = cartHelpers.azRbacRoleToCartItem({
            targetPerson,
            assignmentType: 'Add',
            azureRbacRole,
            assignmentStatus,
            location,
            ...baseObj,
        })
        if (!itemToAdd) return undefined

        return itemToAdd
    }

    const getPreValidationMessage = () => {
        if (!Boolean(location)) {
            return t('AzureRbacRoles_SelectScope')
        }
        if (assignmentStatus && assignmentStatus.isAssigned) {
            return t('ResourceAlreadyAssigned')
        }
        return ''
    }

    return (
        <Fragment>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
            </ItemDetails.Tabs>

            {activeTab === 'overview' && (
                <OverviewSection azureRbacRole={azureRbacRole} />
            )}
            {activeTab === 'additionalInformation' && (
                <AdditionalInformationSection rbacRole={azureRbacRole} />
            )}

            {!isSmallScreen && (
                <PromptContainer>
                    <Box
                        width="172px"
                        display="flex"
                        flexDirection="column"
                        zIndex={1}
                    >
                        <Typography
                            style={{
                                fontSize: '14px',
                                lineHeight: 1.43,
                                fontWeight: 600,
                                textTransform: 'uppercase',
                            }}
                        >
                            {t('AzureRbacRoles_SelectScope')}
                        </Typography>
                        <Box
                            marginTop="24px"
                            width="100%"
                            display="flex"
                            justifyContent="center"
                        >
                            <DifferentiationValueIcon />
                        </Box>
                    </Box>

                    <Box style={{ flexGrow: 1 }} paddingLeft="16px">
                        <Box>
                            <EidTree
                                url={`/api/AzureRoles/rbacRoleLocations/${azureRbacRole.id}`}
                                onChange={(item) => {
                                    setLocation(item)
                                }}
                                value={location}
                                searchable
                                searchPlaceholder={t('Common_Search')}
                                searchContainerStyle={{
                                    backgroundColor:
                                        theme?.palette?.background?.paper,
                                    width: '352px',
                                }}
                                treeContainerStyle={{
                                    paddingX: '10px',
                                    width: '100%',
                                    padding: '10px',
                                    overflow: 'auto',
                                    borderRadius: '5px',
                                    boxShadow:
                                        '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
                                    backgroundColor:
                                        theme?.palette?.common?.white,
                                }}
                                radioSelection={false}
                            />
                        </Box>
                        {location?.instructions && (
                            <Box sx={{ mt: '3.2rem' }}>
                                <TextWithIcon
                                    iconName={'InfoNew'}
                                    message={location?.instructions}
                                />
                            </Box>
                        )}
                    </Box>
                </PromptContainer>
            )}

            {isSmallScreen && (
                <PaddedDiv>
                    <Box
                        paddingY="24px"
                        display="flex"
                        flexDirection="column"
                        position="relative"
                    >
                        <Box position="absolute" top="16px" right="0">
                            <DifferentiationValueIcon
                                width="107px"
                                height="100px"
                            />
                        </Box>
                        <Box width="172px" paddingY="4px" zIndex={1}>
                            <Typography
                                style={{
                                    fontSize: '14px',
                                    lineHeight: 1.43,
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                }}
                            >
                                {t('AzureRbacRoles_SelectScope')}
                            </Typography>
                        </Box>
                        <Box marginTop="32px" paddingY="4px" zIndex={1}>
                            <Box
                                width="100%"
                                paddingY="10px"
                                height="232px"
                                overflow="auto"
                                borderRadius="5px"
                                boxShadow="0 2px 16px 0 rgba(0, 0, 0, 0.11)"
                                style={{
                                    backgroundColor:
                                        theme?.palette?.background?.paper,
                                }}
                            >
                                <EidTree
                                    url={`/api/AzureRoles/rbacRoleLocations/${azureRbacRole.id}`}
                                    onChange={(item) => setLocation(item)}
                                    value={location}
                                    searchable
                                    searchPlaceholder={t('Common_Search')}
                                />
                            </Box>
                        </Box>
                    </Box>
                </PaddedDiv>
            )}
            <>
                {location && (
                    <>
                        {isCheckingAccess ? (
                            <Box padding={'24px'}>
                                <Skeleton height={40} />
                            </Box>
                        ) : azureRbacRole?.canActivateNow ? (
                            <ActivateNowButton
                                resource={azureRbacRole}
                                resourceType={currentResourceType}
                                forDetailsDrawer={true}
                                width="300px"
                            />
                        ) : (
                            <AddToCart
                                resourceType={azureRbacRoleType}
                                resource={azureRbacRole}
                                secondary={location}
                                preValidate={getPreValidationMessage}
                                preAdd={preAdd}
                                postAdd={toggleDrawer}
                            />
                        )}
                    </>
                )}
            </>

            {location && (
                <GlobalSensitiveFunctions
                    azureRbacRole={azureRbacRole}
                    locationId={location?.id}
                />
            )}
        </Fragment>
    )
}

export default AzureRbacRoleDetails
